<template>
  <div class="row">
    <div class="col-12 col-lg-5 c g">
        <div class="card card-body">
            <div class="form-group">
              <h5 for="">
                اختر التاريخ
              </h5>
              <input type="date"
                class="form-control form-control-lg" v-model="date" @change="getDevices()">
            </div>
        </div>
    </div>
    <div class="col-12 g" v-if="!user.pers || user.pers.includes('*')">
      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-header text-center">
              <h1>
                {{ stats.today_outcome_messages ? stats.today_outcome_messages : '---' }}
              </h1>
            </div>
            <div class="card-body">
              <i class="fa fa-paper-plane"></i>
              الرسائل المرسلة
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-header text-center">
              <h1>
                {{ stats.today_messages ? stats.today_messages : '---' }}
              </h1>
            </div>
            <div class="card-body">
              <i class="fa fa-comment"></i>
              الرسائل المستلمة
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-header text-center">
              <h1>
                {{ stats.today_chats ? stats.today_chats : '---' }}
              </h1>
            </div>
            <div class="card-body">
              <i class="fa fa-comments"></i>
              المحادثات الغير مقروءة المفتوحة
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 g" v-if="!user.pers || user.pers.includes('*')">
      <div class="card">
        <div class="card-header">
          <h5>
            <i class="fa fa-users"></i>
            احصائيات المشرفين
          </h5>
        </div>
        <div class="card-body">
          <div class="col-12 table-responsive">
            <table class="table table-hover table-bordered table-sm table-striped">
              <thead>
                <th>
                  المشرف
                </th>
                <th>
                  المحادثات الغير مقروءة المفتوحة
                </th>
                <th>
                  الرسائل المرسلة
                </th>
                <th>
                  آخر رسالة مرسلة
                </th>
              </thead>
              <tbody>
                <tr v-for="admin in stats.admins" :key="admin.name">
                  <td>
                    {{ admins_names[admin.name] }}
                  </td>
                  <td>
                    {{ admin.today_chats }}
                  </td>
                  <td>
                    {{ admin.today_outcome_messages }} <a href="javascript:;" v-b-modal.modal-1 @click="getAdminMessages(admin.name)" class="fa fa-eye"></a>
                  </td>
                  <td>
                    {{ timeSince(new Date(admin.last_outcome_message)) == 'NaN ثانية' ? '--' : 'منذ ' + timeSince(new Date(admin.last_outcome_message)) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" title="الرسائل المرسلة من المشرف" size="lg" hide-footer>
      <div class="alert alert-info g text-center">
        الرسائل هنا لا تكون بشكل لحظي، تتأخر حوالي دقيقة عن الوقت الفعلي للظهور هنا.
      </div>
      <div class="col-12 table-responsive">
        <table class="table table-sm table-hover table-striped">
          <thead>
            <th>
              العميل
            </th>
            <th>
               النوع
            </th>
            <th>
              الرسالة
            </th>
            <th>
              التاريخ
            </th>
          </thead>
          <tbody>
            <tr v-for="(m,i) in messages" :key="i">
              <td>
                {{ m.to ? m.to.split('@')[0] : m.to }}
              </td>
              <td>
                {{ m.message_type }}
              </td>
              <td>
                {{ m.message_type == 'text' ? m.body : '--' }}
              </td>
              <td>
                {{ m.date ? m.date.replace('T', ' ').split(".")[0] : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BOverlay, BModal, VBModal } from "bootstrap-vue";
var QRCode = require("qrcode");
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BOverlay,
    BModal,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data(){
    return {
      user: JSON.parse(localStorage.getItem('user')),
      loading: false,
      devices: [],
      inertValDevices: null,
      current_device: {},
      qrcodeaftergenerate: "",
      date: new Date().toISOString().split('T')[0],
      stats: {
        admins: []
      },
      messages: [],
      admins_names: {
        "main-user": 'المسؤول',
        'ai': 'دربكش AI'
      }
    }
  },
  created(){
    var g = this;
    this.loading = true
    g.getDevices()
    $.post(api + '/user/admins/list', {
          jwt: g.user.jwt
      }).then(function(r){
          if(r.status != 100){
          }else{
              r.response.forEach(function(a){
                g.admins_names[a._id] = a.name;
              })
          }
      }).fail(function(){
          alert("حدث مشكلة في الاتصال")
      })
  },
  methods: {
    timeSince(date) {

        var seconds = Math.floor((new Date() - date) / 1000);

        var interval = seconds / 31536000;

        if (interval > 1) {
            return Math.floor(interval) + " سنة";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " شهر";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " يوم";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " ساعة";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " دقيقة";
        }
        return Math.floor(seconds) + " ثانية";
        },
    gotochat(id){
      window.location = '/chat#' + id
    },
    getAdminMessages(name){
      var g = this;
      g.messages = [];
      $.post(api + '/user/devices/messages', {
            jwt: this.user.jwt,
            id: name.replace("main-user", g.user._id),
            date: g.date,
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.messages = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
    getDevices(){
    var g = this;
    $.post(api + '/user/devices/stats', {
            jwt: this.user.jwt,
            date: g.date
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.stats = r.response
            }
        }).fail(function(){
            g.loading = false
        })
    },
  }
}
</script>

<style>
.ddd:hover{
    background: #eee;
    cursor: pointer;
}
</style>